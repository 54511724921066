import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout/layout';

import { Fullscreen } from '../components/fullscreen/fullscreen';
import { Background } from '../components/background/background';
import { CASE_STUDIES, PRIMARY_SUBTITLE_COLOR, PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR, TEXT_FONT, TITLE_FONT } from '../constants';

import cover from '../../static/images/case-studies/cover.png';
import { Col, Container, Row } from 'reactstrap';
import { LetsGetStarted } from '../components/shared/lets-get-started';
import { Link } from 'gatsby';

import { FontIcon } from '../components/shared';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { MAX_PAGES } from '.';

const TitleContainer = styled(Container)`
    z-index: 1;
`;

const Title = styled.h1`
    font-family: ${TITLE_FONT};
    font-size: 102px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: -6.3px;
    color: ${PRIMARY_TEXT_COLOR};

    @media (max-width: 768px) {
        font-size: 68px;
        letter-spacing: -2px;
    }
`;

const Wrapper = styled.div`
    padding-bottom: 150px;
`;

const CaseTitle = styled.h3`
    font-family: ${TITLE_FONT};
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: ${PRIMARY_TEXT_COLOR};
    margin-bottom: 15px;
`;

const CaseDescription = styled.p`
    font-family: ${TEXT_FONT};
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 0.5px;
    color: ${PRIMARY_TEXT_COLOR};
    margin-top: 40px;
    white-space: pre-line;
`;

const SeeMoreContainer = styled.div`
    a {
        color: ${PRIMARY_TEXT_COLOR};
        font-family: ${TITLE_FONT};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.1px;
    }

    a:hover {
        color: ${PRIMARY_SUBTITLE_COLOR};
    }
`;

const ClutchReviewContainer = styled.div`
    a {
        color: ${PRIMARY_TEXT_COLOR};
        font-family: ${TITLE_FONT};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.1px;
    }

    a:hover {
        color: ${PRIMARY_SUBTITLE_COLOR};
    }

    margin-bottom: 20px;
`;

const SpacedRows = styled(Row)`
    margin-top: 120px;
`;

const SeeMore = ({ to }) => {
    return (
        <SeeMoreContainer>
            <Link to={to}>
                See more <FontIcon></FontIcon>
            </Link>
        </SeeMoreContainer>
    );
};

const ClutchReview = ({ href }) => {
    return (
        <ClutchReviewContainer>
            <a href={href} target="_blank" rel="noopener noreferrer nofollow">
                Clutch review <FontIcon></FontIcon>
            </a>
        </ClutchReviewContainer>
    );
};

const ClutchName = styled.h2`
  font-family: ${TITLE_FONT};
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: ${props => props.inputColor || PRIMARY_TEXT_COLOR};
  z-index: 1;
  
  position: absolute;
  top: 0;
  left: 10px;
}
`;

export default () => {
    const { canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);
    return (
        <Layout showShadow={true} setCanScroll={setCanScroll} canScroll={canScroll}>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <Background bg={cover} design={'cover'} index={1} />
                <TitleContainer>
                    <Title>
                        Case <br /> studies.
                    </Title>
                </TitleContainer>
            </Fullscreen>
            <Wrapper>
                <Container>
                    {CASE_STUDIES.map(item => (
                        <SpacedRows key={item.name}>
                            <Col xs={{ order: 1 }} xl={{ size: 4, order: 0 }}>
                                {item.title.map(title => (
                                    <CaseTitle key={item.name + title}>{title}</CaseTitle>
                                ))}
                                <CaseDescription>{item.description}</CaseDescription>
                                {item.more ? <SeeMore to={item.more} /> : null}
                                {item.clutch ? <ClutchReview href={item.clutch} /> : null}
                            </Col>
                            <Col xs={{ order: 0 }} xl={{ size: 8, order: 1 }} className="mb-5">
                                <Background
                                    pos={'relative'}
                                    position={'center'}
                                    mobilePos={'bottom'}
                                    height={'400px'}
                                    bg={item.image}
                                    index={0}
                                    color={item.color}
                                >
                                    <ClutchName inputColor={item.nameColor}>{item.name}</ClutchName>
                                </Background>
                            </Col>
                        </SpacedRows>
                    ))}
                </Container>
            </Wrapper>
            <LetsGetStarted bg={PRIMARY_TEXT_COLOR} color={PRIMARY_TITLE_COLOR} />
        </Layout>
    );
};
